<div class="form-input">
     <div nz-row  [nzGutter]="24">
        <div nz-col [nzSm]="{span:24}">
            <form nz-form [formGroup]="addTemplateForm" class="custom-form">
                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4"  nzRequired>Content Template Type</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1" [nzValidateStatus]="getControlValidationClass('contentTemplateType')">
                      <nz-radio-group class="yy" nzButtonStyle="solid" formControlName="contentTemplateType"
                        [(ngModel)]="type" (ngModelChange)="getSenderIdsByMsgType($event)">
                        <label nz-radio-button nzValue="trans">Transactional</label>
                        <label nz-radio-button nzValue="promo">Promotional</label>
                        <label nz-radio-button nzValue="others">Service</label>
                      </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>

                <nz-form-item *ngIf="type === 'others'">
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Service Type</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group nzButtonStyle="solid" formControlName="contentTemplateSubType"
                            (ngModelChange)="getSenderIdsByMsgType(type,$event)">
                            <label class="xs" nz-radio-button nzValue="service-implicit">Service Implicit</label>
                            <label nz-radio-button nzValue="service-explicit">Service Explicit</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="rt" [ngClass]="getControlValidationClass('senderId')">
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Select Sender ID</nz-form-label>
                    <nz-form-control [nzSm]="12">
                      <nz-select nzShowSearch nzAllowClear class="rr" nzPlaceHolder="Select Sender Id"
                        formControlName="senderId" (ngModelChange)="entityId = $event.entityId">
                        <nz-option [nzLabel]="item.senderId" [nzValue]="item" *ngFor="let item of listOfSenderIds">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>



                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Entity ID</nz-form-label>
                    <nz-form-control [nzSm]="12">
                        <input [style.-webkit-text-security]="userType !== 'web' ? 'disc' : 'none'" nz-input class="ok" placeholder="Entity ID"  formControlName="entityId"
                            [(ngModel)]="entityId" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="gt">
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Content Template Name</nz-form-label>
                    <nz-form-control [nzSm]="12">
                        <input nz-input class="hj" placeholder="Content Template Name"
                            formControlName="templateTitle" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item class="nj">
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Content Template Description</nz-form-label>
                    <nz-form-control [nzSm]="12">
                        <input nz-input class="kl" placeholder="Content Template Description"
                            formControlName="templateDescription" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Operator Template ID</nz-form-label>
                    <nz-form-control [nzSm]="12" >
                        <input [style.-webkit-text-security]="userType !== 'web' ? 'disc' : 'none'" #inputElement nz-input [ngModel]="value" class="nm"
                            formControlName="operatorTemplateId" placeholder="Operator Template" (ngModelChange)="onChange($event)" (blur)="onBlur()" />
                    </nz-form-control>
                </nz-form-item>

                        <nz-form-item class="vb">
                            <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Message Text</nz-form-label>
                            <nz-form-control [nzSm]="12">
                                <!-- <nz-textarea-count> -->
                                <textarea rows="4" #textArea class="nb" formControlName="templateText" placeholder="Message Text"
                                    nz-input></textarea>
                                    <button style="margin-top: 2%;" nz-button nzType="primary" class="vf" (click)="insertVariable(textArea)">Insert
                                        Variable</button>
                                  <!-- </nz-textarea-count> -->
                            </nz-form-control>
                        </nz-form-item>


                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4"  nzRequired>Template Type</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group nzButtonStyle="solid" formControlName="templateType">
                            <label style="width: 125px; text-align: center;"  nz-radio-button  nzValue="PM">  Plain Text</label>
                            <label style="width: 125px; text-align: center;" nz-radio-button nzValue="UC">Unicode</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>
                <!-- <nz-form-item class="opp">
                    <nz-form-label class="txt-left" [nzSm]="8" nzRequired>Status</nz-form-label>
                    <nz-form-control [nzSm]="12" >
                        <nz-select nzShowSearch nzAllowClear class="vbb" nzPlaceHolder="Select Status"
                            formControlName="status">
                            <nz-option [nzLabel]="'Active'" [nzValue]="'active'"></nz-option>
                            <nz-option [nzLabel]="'InActive'" [nzValue]="'inactive'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item> -->
                <nz-form-item>
                    <nz-form-control style="margin-left: 53%;">
                        <button nz-button nzType="primary" (click)="submitForm()" style="width: 128px;">Submit</button>
                        <button nz-button (click)="resetForm()" style="width: 120px;">Clear</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </div>

</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Preview" [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="handleCancel()">

    <ng-template #modalContent>

        <p><span class="title">Content Template Type : </span><span>
                {{modalData.contentTemplateType === 'trans' ? 'Transactional' : ''}}
                {{modalData.contentTemplateType === 'promo' ? 'Promotional' : ''}}
                {{modalData.contentTemplateType === 'service' ? 'Service' : ''}}
            </span></p>
        <p><span class="title">Template Name :</span> <span>{{modalData.templateTitle}}</span></p>
        <p><span class="title">Template Type :</span> <span>{{modalData.templateType}}</span></p>
        <p><span class="title">Message Text :</span> <span>{{modalData.templateText}}</span></p>
        <p><span class="title">Select Sender Id :</span> <span>{{modalData.senderId}}</span></p>
        <p><span class="title">Operator Template Id :</span> <span>{{modalData.operatorTemplateId}}</span></p>
        <p><span class="title">Variable Count :</span> <span>{{modalData.variableCount}}</span></p>
        <p><span class="title">Status :</span> <span>{{modalData.status}}</span></p>
    </ng-template>

    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
        <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isOkLoading">Submit</button>
    </ng-template>

</nz-modal>
