import { Component, ElementRef,HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { NUMBER_REGEX } from 'src/environments/environment';


@Component({
  selector: 'app-add-content-template',
  templateUrl: './add-content-template.component.html',
  styleUrls: ['./add-content-template.component.scss']
})
export class AddContentTemplateComponent implements OnInit {

  listOfSenderIds: any;
  type: string = 'trans';
  entityId: any;
  value = '';
userType:any='';
  isVisible = false;
  isOkLoading = false;
  modalData: any;
   userId: any;
   listOfData:any
   page=1;
   isSmallScreen = false;
   @HostListener('window:resize', ['$event'])
   onResize(event: Event): void {
     this.checkScreenSize();
   }

  addTemplateForm: FormGroup = this.fb.group({
    contentTemplateSubType: "service-implicit",
    contentTemplateType: "trans",
    entityId: ['', [Validators.required]],
    loggedInUserName: this.storageService.username,
    operation: "addContentTemplate",
    operatorTemplateId: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    senderId: ['', [Validators.required]],
    status: "active",
    templateDescription: ['', [Validators.required]],
    templateText: ['', [Validators.required]],
    templateTitle: ['', [Validators.required]],
    templateType: "PM",
    variableCount: 1
  });
  operatorTemplateId: any;

  constructor(
    private templateService: TemplateService,
    private storageService: StorageService,
    private utils: UtilsService,
    private fb: FormBuilder,
    private reportService:ReportService
  ) {
if (localStorage.getItem('role')) {
    this.userType = localStorage.getItem('role');
  }
  }

  getControlValidationClass(controlName: string): string {
    const control = this.addTemplateForm.get(controlName);

    if (!control) {
      return '';
    }

    if (control.invalid && (control.dirty || control.touched)) {
      return 'has-error';
    } else if (control.valid && (control.dirty || control.touched)) {
      return 'has-success';
    }

    return '';
  }


  ngOnInit(): void {
    this.checkScreenSize()
    this.userId = this.storageService.userId;
    this.addTemplateForm.patchValue({
      loggedInUserName: this.storageService.username

    });

    this.getSenderIdsByMsgType();

  }
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  getSenderIdsByMsgType(type: string = this.type, subType: string = 'service-implicit') {
    let sub = type === 'others' ? subType : '';
    this.templateService.getSenderIdByMsgType(type, sub).subscribe(data => {
      this.listOfSenderIds = data.data.senderIdList;
    })
    this.addTemplateForm.get('senderId')?.reset?.();
    this.addTemplateForm.get('entityId')?.reset?.();
  }

  onChange(value: string): void {
    this.updateValue(value);
  }

  // '.' at the end or only '-' in the input box.
  onBlur(): void {
    if (this.value.charAt(this.value.length - 1) === '.' || this.value === '-') {
      this.updateValue(this.value.slice(0, -1));
    }
  }

  updateValue(value: string): void {
    if (this.utils.isNumber(value)) {
      this.value = value;
    }

  }

  showModal(): void {
    this.isVisible = true;
  }

  resetAddBlacklistForm(){
    this.addTemplateForm.patchValue({
      entityId: [''],
      operatorTemplateId: [''],
      senderId: [''],
      templateDescription: [''],
    templateText: [''],
    templateTitle: [''],

    });
  }

  handleOk(): void {
    let params = {
      "loggedInUserName": this.storageService.username,
      "pagination": this.page,
      "recordRange": 50
    }
    this.isOkLoading = true;
    this.templateService.saveTemplate(this.modalData).subscribe(data => {
      this.isVisible = false;
      this.isOkLoading = false;
      this.utils.showSuccessMsg(data.message);
      this.reportService.getAllContentTemplates(params).subscribe(data => {
        this.listOfData = data?.data?.contentTemplateList;

        // this.downloadLink = data?.data?.downloadReportLink ? data?.data?.downloadReportLink.match(/href='(.*?)'/)[1] : null;
        this.reportService.viewRefresh(this.listOfData)
        console.log(this.listOfData)
        this.resetForm()
      })

    }, err => {
      this.isOkLoading = false;
      this.utils.showErrorMsg("Something Went Wrong");
    });


  //   setTimeout(()=>{
  //     // this.getAllTemplates()
  //     window.location.reload
  //  }, 2000);


  }

  handleCancel(): void {
    this.isVisible = false;
  }

  submitForm() {

    if (!this.addTemplateForm.valid) {
      this.utils.showErrorMsg("Please Fill Required Field")
      Object.values(this.addTemplateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }


    let formData = this.addTemplateForm.getRawValue();
    formData.variableCount = this.getVariableCount();
    formData.userId = this.userId;
    if (formData.contentTemplateType !== "others") {
      delete formData.contentTemplateSubType;
    }
    else{
      delete formData.contentTemplateType;
       formData = {...this.addTemplateForm.getRawValue(),"contentTemplateType": "service",}
    }
    if (this.utils.isPlainMsg(formData.templateText) && formData.templateType === "UC") {
      this.utils.showErrorMsg("Plain Text only found in Message Text. Please change the Type")
    } else if (!this.utils.isPlainMsg(formData.templateText) && formData.templateType === "PM") {
      this.utils.showErrorMsg("Unicode Text found in Message Text. Please change the Type")
    } else {
      formData.senderId = formData.senderId['senderId'];
      this.modalData = formData;
      this.showModal();
    }

    // this.addTemplateForm.reset();
  // window.location.reload
  }

  setDefaultData() {
    this.addTemplateForm.patchValue({
      contentTemplateSubType: "service-implicit",
      contentTemplateType: "trans",
      loggedInUserName: this.storageService.username,
      operation: "addContentTemplate",
      status: "active",
      templateType: "PM",
      userId: this.storageService.userId,
    });
  }

  resetForm() {
    this.addTemplateForm.reset();
    this.setDefaultData();
    this.operatorTemplateId.reset();

  }

  getVariableCount() {
    let formData = this.addTemplateForm.getRawValue();
    let count = (formData.templateText.match(/{#var#}/g) || []).length;
    return count;
  }

  insertVariable(field: any) {
    var cursorPos = this.getCaretPos(field);
    var msgText = this.addTemplateForm.value.templateText;
    var textBefore = msgText.substring(0, cursorPos);
    var textAfter = msgText.substring(cursorPos, msgText.length);
    this.addTemplateForm.patchValue({
      templateText: textBefore + "{#var#}" + textAfter
    });
  }

  getCaretPos(oField: any) {
    if (oField.selectionStart || oField.selectionStart == '0') {
      return oField.selectionStart;
    } else {
      return 0;
    }
  }

}
