<div class="form-input">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <div nz-row [nzGutter]="24">
            <div nz-col>
                <nz-form-item>
                    <nz-form-label nzRequired>Sender ID Type</nz-form-label>
                    <nz-form-control>
                        <nz-radio-group nzButtonStyle="solid" formControlName="senderIdType"
                            (ngModelChange)="updateValidator($event)">
                            <label nz-radio-button class="kl" nzValue="promo">Promotional</label>
                            <label nz-radio-button nzValue="others">Others</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col nzSpan="8" >

                <nz-form-item>
                    <nz-form-label nzRequired>New / Existing Entity ID</nz-form-label>
                    <nz-form-control [nzErrorTip]="nameErrorTpl">
                        <input [style.-webkit-text-security]="userType !== 'web' ? 'disc' : 'none'"  nz-input   placeholder="" [ngModel]="entityIdValue" list="id-car" placeholder="Enter Entity ID"
                            formControlName="entityId" required />
                        <datalist id="id-car">
                            <option *ngFor="let item of listEntityIds" [value]="item.entityId">{{item.entityId}}
                            </option>
                        </datalist>
                        <ng-template #nameErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')"><small>This field is required</small>
                            </ng-container>
                            <ng-container *ngIf="control.hasError('pattern')">numeric input expected</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>


            <div nz-col nzSpan="5" >

                <nz-form-item>
                    <nz-form-label>Header ID</nz-form-label>
                    <nz-form-control [nzErrorTip]="headerIdErrorTpl">
                        <input nz-input placeholder="" formControlName="headerId" placeholder="Enter Header ID" />
                        <ng-template #headerIdErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('pattern')">numeric input expected</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="5" >

                <nz-form-item>
                    <nz-form-label nzRequired>New Sender ID</nz-form-label>
                    <nz-form-control [nzErrorTip]="senderIDErrorTpl">
                        <!-- <input nz-input placeholder="" formControlName="senderId" [ngModel]="senderIDValue" required /> -->
                        <input nz-input placeholder="Enter Sender ID" formControlName="senderId" [ngModel]="senderIDValue" *ngIf="validateForm.get('senderIdType')?.value === 'promo'" required />
                        <input nz-input placeholder="" formControlName="senderId" [ngModel]="senderIDValue" *ngIf="validateForm.get('senderIdType')?.value === 'others'" required pattern="^[a-zA-Z0-9]+$" />
                        <ng-template #senderIDErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('pattern')">
                                <small>{{senderIdToolTip}}</small>
                            </ng-container>
                            <ng-container *ngIf="control.hasError('required')"><small>This field is required</small>
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="6" >
                <nz-form-item>
                    <nz-form-control>
                        <button nz-button  nzType="primary">Submit</button>
                    </nz-form-control>
                </nz-form-item>
            </div>




                <!-- <div nz-col [nzSpan]="3">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-input-group nzSearch>
                                <input nz-input formControlName="search" placeholder="input search text" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="1">
                    <nz-form-item>
                        <nz-form-control>
                            <button nz-button nzType="primary" nzSearch type="button" (click)="onSearch()">
                                <span nz-icon nzType="search"></span>
                            </button>
                        </nz-form-control>
                    </nz-form-item>
                </div> -->
            </div>

    </form>
</div>

<app-simple-table [listOfData]="listOfData" [listOfColumn]="listOfColumn" [pagination]="true" [type]="'sender-id'"
    (deleteTriggred)="deleteSenderId($event)"></app-simple-table>
