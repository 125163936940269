<div class="form-input">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" nzLayout="vertical">
        <div nz-row [nzGutter]="24" nzAlign="middle">
            <div nz-col>
                <nz-form-item>
                    <nz-form-label nzRequired>Select Range</nz-form-label>
                    <nz-form-control>
                        <!-- [nzDefaultPickerValue]="today"  -->
                        <nz-range-picker formControlName="rangePicker" [nzDisabledDate]="disabledDate"
                            [nzRanges]="ranges" nzFormat="yyyy-MM-dd">
                        </nz-range-picker>

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-item>
                    <nz-form-label>Mobile Number</nz-form-label>
                    <nz-form-control>
                        <nz-input-group nzAddOnBefore="+91">
                            <input type="text" nz-input [(ngModel)]="mobile" formControlName="mobileNumber" placeholder="Search Mobile Number" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-item>
                    <nz-form-label>Sender ID</nz-form-label>
                    <nz-form-control>
                        <input type="text" nz-input formControlName="senderId" placeholder="SearchSender ID" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-item>
                    <nz-form-label>Message ID</nz-form-label>
                    <nz-form-control>
                        <input type="text" nz-input formControlName="messageId" placeholder="Search Message Id" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <!-- <div nz-col>
                <nz-form-item>
                    <nz-form-label>Template ID</nz-form-label>
                    <nz-form-control>
                        <input type="text" nz-input formControlName="templateId" placeholder="Search Template Id" />
                    </nz-form-control>
                </nz-form-item>
            </div> -->
            <div nz-col>
                <nz-form-control>
                    <button nz-button nzType="primary">Submit</button>
                </nz-form-control>
            </div>
            <div nz-col *ngIf="downloadLink">
                <button nz-button nzType="primary" (click)="downloadFile()">
                    <span nz-icon nzType="download"></span>
                    Download
                </button>
            </div>
        </div>
    </form>
</div>

<div style="text-align: right;">
    <app-simple-table [listOfData]="listOfData | async" [listOfColumn]="listOfColumn" [pagination]="false" [type]="'detailed-report'"></app-simple-table>

<div class="carousel-controls" style="display: flex; justify-content: flex-end;">
      <button style="margin-right: 1px;" nz-button nzType="primary" (click)="previousPage()" [disabled]="pageNumber == 1">Previous</button>
      <button nz-button nzType="primary" (click)="nextPage()" [disabled]="listOfData == ''">Next</button>

  </div>