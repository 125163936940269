import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { NUMBER_REGEX, NUMERIC_6_REGEX, NUMERIC_REGEX, SENDER_ID_REGEX } from 'src/environments/environment';

@Component({
  selector: 'app-add-view-senderid',
  templateUrl: './add-view-senderid.component.html',
  styleUrls: ['./add-view-senderid.component.scss']
})
export class AddViewSenderidComponent implements OnInit {

  listOfColumn = [
    {
      title: 'Sender ID',
      compare: (a: any, b: any) => a.senderId - b.senderId,
      priority: false
    }, {
      title: 'Active',
      compare: (a: any, b: any) => a.isActive - b.isActive,
      priority: 3
    }, {
      title: 'Default',
      compare: (a: any, b: any) => a.isDefault - b.isDefault,
      priority: 2
    },
     {
      title: 'Entity Id',
      compare: (a: any, b: any) => a.entityId - b.entityId,
      priority: 1
    },
     {
      title: 'Header Id',
      compare: (a: any, b: any) => a.headerId - b.headerId,
      priority: 4
    }, {
      title: 'Sender Id Type',
      compare: (a: any, b: any) => a.senderIdType - b.senderIdType,
      priority: 5
    },
    // {
    //   title: 'created Date',
    //   compare: (a: any, b: any) => a.createdDate - b.createdDate,
    //   priority: 6
    // },
    // {
    //   title: 'Action',
    //   compare: false
    // }
  ];


  listOfData: any;
  listEntityIds: any;
  pageNumber: number = 1;
  validateForm!: FormGroup;
userType:any='';

  entityIdValue: string = '';
  senderIDValue: String = '';
  senderIdToolTip: string = 'Sender ID must contain numeric characters,\n special characters are not allowed';
  search1!: FormGroup;


  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService) { 
        if (localStorage.getItem('role')) {
    this.userType = localStorage.getItem('role');
  }
    }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      headerId: ['', Validators.pattern(NUMBER_REGEX)],
      senderId: ['', [Validators.pattern(NUMERIC_6_REGEX)]],
      entityId: ['', [Validators.pattern(NUMBER_REGEX)]],
      loggedInUserName: this.storageService.username,
      operation: "addSenderId",
      userId: [''],
      search:'',
      senderIdType: ['promo']
    });






    this.getAllSenderIdList();
  }

  deleteSenderId(data: any) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "idOfSenderId": data.id,
      "operation": "removeSenderIdFromList",
      "senderId": data.senderId
    }
    this.reportService.deleteSenderId(params).subscribe(data => {
      this.getAllSenderIdList();
      if (data.result.toLowerCase() === 'success') {
        this.utilsService.showSuccessMsg(data.message)
      } else {
        this.utilsService.showErrorMsg(data.message)
      }
    })
  }

  updateValidator(value: string): void {
    const reg = value === "others" ? /^[a-zA-Z0-9]+$/ : NUMERIC_6_REGEX;
    this.senderIdToolTip = value === "others" ?
      'Sender ID must contain alpha numeric characters,\n special characters are not allowed' :
      'Sender ID must contain numeric characters,\n special characters are not allowed';

    this.validateForm.get('senderId')?.setValidators([Validators.required, Validators.pattern(reg)]);
    this.validateForm.get('senderId')?.updateValueAndValidity();

  }

  submitForm() {
    if (this.validateForm.valid) {
      let dt = {
        headerId: this.validateForm.get('headerId')?.value,
        senderId: this.validateForm.get('senderId')?.value,
        entityId: this.validateForm.get('entityId')?.value,
        loggedInUserName: this.storageService.username,
        operation: "addSenderId",
        userId:this.validateForm.get('userId')?.value,
        senderIdType: this.validateForm.get('senderIdType')?.value,
      }
      this.reportService.addSenderID(dt).subscribe(data => {
        this.resetForm();
        if (data.result === "Success") {
          this.utilsService.showSuccessMsg(data.message ? data.message : 'Added Successfully');
          this.getAllSenderIdList();
        } else {
          this.utilsService.showErrorMsg(data.message ? data.message : 'Unable to Add Sender ID');
        }
      },
     );

    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  resetForm() {
    this.validateForm.reset();
    this.validateForm.patchValue({
      loggedInUserName: this.storageService.username,
      operation: "addSenderId",
      senderIdType: 'promo'
    });
  }
  getAllSenderIdList(page: any = this.pageNumber) {
    let params = {
      "loggedInUserName": this.storageService.username
    }
    this.reportService.getAllSenderIdList(params).subscribe(data => {
      this.listOfData = data.data.senderIdList;

      this.listEntityIds = [
        ...new Map(this.listOfData.map((item: any) => [item["entityId"], item])).values(),
      ];
    })
  }

  onSearch(page: any = this.pageNumber){

    let params = {
      "loggedInUserName": this.storageService.username,
      "senderId":this.validateForm.get('search')?.value
    }
    this.reportService.getAllSenderIdList(params).subscribe(data => {
      this.listOfData = data.data.senderIdList;

      this.listEntityIds = [
        ...new Map(this.listOfData.map((item: any) => [item["entityId"], item])).values(),
      ];
    })
}
}
